import React, { Component } from 'react';

import Button from 'react-bootstrap/lib/Button'
import FormGroup from 'react-bootstrap/lib/FormGroup'
import Label from 'react-bootstrap/lib/Label'
import Col from 'react-bootstrap/lib/Col'
import FormControl from 'react-bootstrap/lib/FormControl'
import Modal from 'react-bootstrap/lib/Modal'
import Row from 'react-bootstrap/lib/Row'
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { Link } from 'react-router-dom';
import  remove  from 'lodash/remove';

import {
  espotAPI,
  PinToCityAPI,
  customerLeadsAPI,
} from '../../../public/constants/constants';
import {
  validateEmptyObject,
  validateFullName,
  regexName,
  regexMobileNo,
  regexEmail,
} from '../../utils/validationManager';
import {
  FIELDREQ_MSG,
  NAME_VALIDATION_MSG,
  INVALID_MOBILE_NUMBER,
  INVALID_EMAIL_ADDRESS,
  INVALID_PINCODE_FORMAT,
  NOT_VALID_PINCODE,
  ATLEAST_ONE_REQUIREMENT,
} from '../../constants/app/primitivesConstants';
import apiManager from '../../utils/apiManager';
import { getCookie } from '../../utils/utilityManager';

const PINCODE_REGEX = /^\d{6}$/;
const animatedComponents = makeAnimated();
const Option = props => (
  <components.Option {...props}>
    <input type="checkbox" checked={props.isSelected} />
    <span />
    <label>{props.label}</label>
  </components.Option>
);

class CustomerLeadsForm extends Component {
  constructor() {
    super();
    this.state = {
      name: null,
      email1: null,
      phone1: null,
      city: null,
      state: null,
      pincode: null,
      requirementsOptions: [],
      requirements: [],
      nameErrMsg: null,
      emailErrMsg: null,
      phoneErrMsg: null,
      pincodeErrMsg: null,
      requirementsErrMsg: null,
      successMsg: null,
      errorMsg: null,
      submitDisabled: false,
      agreeTermsText1: '',
      agreeTermsText2: '',
      agreeTermsText3: '',
      agreeTermsURLText1: '',
      agreeTermsURLLink1: '',
      agreeTermsURLText2: '',
      agreeTermsURLLink2: '',
      agreeTermsErrMsg: '',
      confirmationPopupTitle: '',
      confirmationPopupText1: '',
      confirmationPopupText2: '',
      confirmationPopupText3: '',
      confirmationPopupText4: '',
      confirmationPopupText5: '',
      confirmationPopupurlText1: '',
      confirmationPopupurlText2: '',
      confirmationPopupurlText3: '',
      confirmationPopupurlText4: '',
      confirmationPopupurlLink1: '',
      confirmationPopupurlLink2: '',
      confirmationPopupurlLink3: '',
      confirmationPopupurlLink4: '',
      aggreeTerms: true,
      agreeTermsErrMsg: '',
      show: false,
    };
  }

  fieldChangeHandler = e => {
    const { pincode } = this.state;
    if (
      e.target.name === 'pincode' &&
      e.target.value &&
      PINCODE_REGEX.test(e.target.value) &&
      pincode !== e.target.value
    ) {
      this.fetchCityFromPin(e.target.value);
    }
    this.setState({
      [e.target.name]: e.target.value !== '' ? e.target.value : null,
    });
  };

  keyPressHandler = e => {
    if (e.key === 'Enter') this.formSubmitHandler();
  };

  requirementsChangeHandler = (value, actionMeta) => {
    const { requirements } = this.state;
    const newRequirements = requirements.slice();
    if (actionMeta.action === 'select-option') {
      newRequirements.push(actionMeta.option);
    } else if (
      actionMeta.action === 'remove-value' ||
      actionMeta.action === 'pop-value'
    ) {
       remove(
        newRequirements,
        requirement => requirement.value === actionMeta.removedValue.value,
      );
    } else if (actionMeta.action === 'deselect-option') {
       remove(
        newRequirements,
        requirement => requirement.value === actionMeta.option.value,
      );
    } else if (actionMeta.action === 'clear') {
       remove(newRequirements, () => true);
    }
    this.setState({
      requirements: newRequirements,
    });
  };

  formValidationHandler = () => {
    this.setState({
      nameErrMsg: null,
      emailErrMsg: null,
      phoneErrMsg: null,
      pincodeErrMsg: null,
      requirementsErrMsg: null,
      successMsg: null,
      errorMsg: null,
    });
    let isValid = true;
    const { name, email1, phone1, pincode, city, requirements,aggreeTerms } = this.state;
    if (!validateEmptyObject(name)) {
      this.setState({ nameErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!validateFullName(name) || !regexName.test(name)) {
      this.setState({ nameErrMsg: NAME_VALIDATION_MSG });
      isValid = false;
    }
    if (!validateEmptyObject(email1)) {
      this.setState({ emailErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!regexEmail.test(email1)) {
      this.setState({ emailErrMsg: INVALID_EMAIL_ADDRESS });
      isValid = false;
    }
    if (!validateEmptyObject(phone1)) {
      this.setState({ phoneErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!regexMobileNo.test(phone1)) {
      this.setState({ phoneErrMsg: INVALID_MOBILE_NUMBER });
      isValid = false;
    }
    if (!validateEmptyObject(pincode)) {
      this.setState({ pincodeErrMsg: FIELDREQ_MSG });
      isValid = false;
    } else if (!PINCODE_REGEX.test(pincode)) {
      this.setState({ pincodeErrMsg: INVALID_PINCODE_FORMAT });
      isValid = false;
    } else if (!validateEmptyObject(city)) {
      this.setState({ pincodeErrMsg: NOT_VALID_PINCODE });
      isValid = false;
    }
    if (!requirements || requirements.length === 0) {
      this.setState({
        requirementsErrMsg: ATLEAST_ONE_REQUIREMENT,
      });
      isValid = false;
    }
    if(aggreeTerms==false){
      this.setState({ agreeTermsErrMsg: 'Please select terms and conditions' });
      isValid = false;
    }
    return isValid;
  };

  handleClose = () => {
    this.setState({ show: false });
  }
  formReviewHandler = () => {
    if (!this.formValidationHandler()) return;
    this.setState({ show: true });
  }

  formSubmitHandler = () => {
    if (!this.formValidationHandler()) return;

    this.setState({
      submitDisabled: true,
    });
    setTimeout(() => {
      this.setState({
        submitDisabled:false,
      });
    }, 5000);
    const {
      name,
      phone1,
      email1,
      pincode,
      city,
      state,
      requirements,
      aggreeTerms,
    } = this.state;
    const requirementsStr = requirements.map(rqrmnt => rqrmnt.value).join('||');
    const urlSearchParams = new URLSearchParams(
      window.location.search.substring(1),
    );
    const data = {
      name,
      phone1,
      email1,
      pincode,
      city,
      state,
      requirements: requirementsStr,
      utmPlacement: urlSearchParams.get('utm_placement'),
      utmMedia: urlSearchParams.get('utm_medium') || getCookie('utm_medium'),
      utmKeyword: urlSearchParams.get('utm_keyword'),
      utmAddgroup: urlSearchParams.get('utm_adgroup'),
      utmCampaign: urlSearchParams.get('utm_campaign') || getCookie('utm_campaign'),
      utmSource: urlSearchParams.get('utm_source') || getCookie('utm_source'),
      aggreeTerms,
    };
    apiManager
      .post(customerLeadsAPI, data)
      .then(response => {
        const resData = response.data.data;
        if (resData.customerQuerySaved === true) {
          this.setState({
            name: '',
            phone1: '',
            email1: '',
            pincode: '',
            city: '',
            state: '',
            requirements: [],
            successMsg:
              'Thank You! Your nearest store representative will reach out to you shortly.',
          });
        } else {
          this.setState({
            errorMsg: 'Unable to save your data. Sorry for inconvenience.',
          });
        }
      })
      .catch(() => {
        this.setState({
          errorMsg: 'Unable to save your data. Sorry for inconvenience.',
        });
      });
  };

  fetchCityFromPin = pincode => {
    if (!pincode) return;
    apiManager
      .get(PinToCityAPI + pincode)
      .then(response => {
        const { data } = response;
        if (data.data && data.data.city) {
          this.setState({
            pincodeErrMsg: null,
            city: data.data.city,
            state: data.data.state,
          });
        } else {
          this.setState({ pincodeErrMsg: NOT_VALID_PINCODE });
        }
      })
      .catch(error => {
        this.setState({ pincodeErrMsg: NOT_VALID_PINCODE });
      });
  };

  fetchRequirementsOptions = () => {
    apiManager
      .get(`${espotAPI}GI_Customer_Leads_Rqrmnts`)
      .then(response => {
        const { data } = response;
        if (data && data.data) {
          this.setState({
            requirementsOptions: data.data.requirements || [],
            agreeTermsText1:  data.data.agreeTermsText1 || '',
            agreeTermsText2:  data.data.agreeTermsText2 || '',
            agreeTermsText3:  data.data.agreeTermsText3 || '',
            agreeTermsURLText1:  data.data.agreeTermsURLText1 || '',
            agreeTermsURLLink1:  data.data.agreeTermsURLLink1 || '',
            agreeTermsURLText2:  data.data.agreeTermsURLText2 || '',
            agreeTermsURLLink2:  data.data.agreeTermsURLLink2 || '',
            confirmationPopupTitle: data.data.confirmationPopupTitle || '',
            confirmationPopupText1: data.data.confirmationPopupText1 || '',
            confirmationPopupText2: data.data.confirmationPopupText2 || '',
            confirmationPopupText3: data.data.confirmationPopupText3 || '',
            confirmationPopupText4: data.data.confirmationPopupText4 || '',
            confirmationPopupText5: data.data.confirmationPopupText5 || '',
            confirmationPopupurlText1: data.data.confirmationPopupurlText1 || '',
            confirmationPopupurlText2: data.data.confirmationPopupurlText2 || '',
            confirmationPopupurlText3: data.data.confirmationPopupurlText3 || '',
            confirmationPopupurlText4: data.data.confirmationPopupurlText4 || '',
            confirmationPopupurlLink1: data.data.confirmationPopupurlLink1 || '',
            confirmationPopupurlLink2: data.data.confirmationPopupurlLink2 || '',
            confirmationPopupurlLink3: data.data.confirmationPopupurlLink3 || '',
            confirmationPopupurlLink4: data.data.confirmationPopupurlLink4 || ''   
          });
        }
      })
      .catch(error => {});
  };

  toggleHandler = () => {
    this.setState({ successMsg: null });
  };

  toggleaggreeTerms = e =>{
    this.setState({aggreeTerms : e.target.checked});
    if(e.target.checked==true){
      this.setState({
        agreeTermsErrMsg: '',
      })
    }
  }
  componentDidMount() {
    this.fetchRequirementsOptions();
  }

  render() {
    const {
      name,
      email1,
      phone1,
      city,
      pincode,
      requirements,
      nameErrMsg,
      emailErrMsg,
      phoneErrMsg,
      pincodeErrMsg,
      requirementsErrMsg,
      successMsg,
      errorMsg,
      requirementsOptions,
      aggreeTerms,
      agreeTermsErrMsg
    } = this.state;
    return (
      <>
      <Modal className='modal_LDM-rcb' show={this.state.show}>
              <Modal.Body>
                <Row>
                  <Col xs={12} md={12}>
                    <div className='ldm_modal'>
                    <h4 className='popup-title-text'>{this.state.confirmationPopupTitle}</h4>
                    <h6 className='popup-info-text'>
                      {this.state.confirmationPopupText1}
                      {this.state.confirmationPopupurlText1 && this.state.confirmationPopupurlText1!='' && 
                      this.state.confirmationPopupurlText1!=null && this.state.confirmationPopupurlText1!=undefined && 
                      this.state.confirmationPopupurlLink1 && this.state.confirmationPopupurlLink1!='' && 
                      this.state.confirmationPopupurlLink1!=null && this.state.confirmationPopupurlLink1!=undefined && 
                      <Link to={this.state.confirmationPopupurlLink1}>{this.state.confirmationPopupurlText1}</Link>
                      }
                      {this.state.confirmationPopupText2}
                      {this.state.confirmationPopupurlText2 && this.state.confirmationPopupurlText2!='' && 
                      this.state.confirmationPopupurlText2!=null && this.state.confirmationPopupurlText2!=undefined && 
                      this.state.confirmationPopupurlLink2 && this.state.confirmationPopupurlLink2!='' && 
                      this.state.confirmationPopupurlLink2!=null && this.state.confirmationPopupurlLink2!=undefined && 
                      <Link to={this.state.confirmationPopupurlLink2}>{this.state.confirmationPopupurlText2}</Link>
                      }
                      {this.state.confirmationPopupText3}
                      {this.state.confirmationPopupurlText3 && this.state.confirmationPopupurlText3!='' && 
                      this.state.confirmationPopupurlText3!=null && this.state.confirmationPopupurlText3!=undefined && 
                      this.state.confirmationPopupurlLink3 && this.state.confirmationPopupurlLink3!='' && 
                      this.state.confirmationPopupurlLink3!=null && this.state.confirmationPopupurlLink3!=undefined && 
                      <Link to={this.state.confirmationPopupurlLink3}>{this.state.confirmationPopupurlText3}</Link>
                      }
                      {this.state.confirmationPopupText4}
                      {this.state.confirmationPopupurlText4 && this.state.confirmationPopupurlText4!='' && 
                      this.state.confirmationPopupurlText4!=null && this.state.confirmationPopupurlText4!=undefined && 
                      this.state.confirmationPopupurlLink4 && this.state.confirmationPopupurlLink4!='' && 
                      this.state.confirmationPopupurlLink4!=null && this.state.confirmationPopupurlLink4!=undefined && 
                      <Link to={this.state.confirmationPopupurlLink4}>{this.state.confirmationPopupurlText4}</Link>
                      }
                      {this.state.confirmationPopupText5}
                    </h6>
                    </div>
                  </Col>
                </Row>
                <Row className='button-row'>
                  <Col xs={6} md={6}>
                    <Button
                    className="submit-btn btn-block btn-bg"
                    // onClick={this.formSubmitHandler}
                    onClick={this.formSubmitHandler}
                    >
                    {'SUBMIT'}              
                    </Button>
                  </Col>
                {/* </Row>
                <Row className='button-row'> */}
                  <Col xs={6} md={6}>
                    <Button
                    className="submit-btn btn-block btn-bg"
                    // onClick={this.formSubmitHandler}
                    onClick={this.handleClose.bind(this)}
                    >
                    {'CANCEL'}
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>

        <Col xs={12}>
          <FormGroup>
            <Label>Name *</Label>
            <FormControl
              type="text"
              name="name"
              className="form-control"
              placeholder="Enter Full Name"
              value={name}
              onChange={this.fieldChangeHandler}
              onKeyPress={this.fieldChangeHandler}
            />
            <p className="error-msg">{nameErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label>Email *</Label>
            <FormControl
              type="text"
              name="email1"
              className="form-control"
              placeholder="Enter Email"
              value={email1}
              onChange={this.fieldChangeHandler}
              onKeyPress={this.fieldChangeHandler}
            />
            <p className="error-msg">{emailErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <Label>Mobile *</Label>
            <FormControl
              type="text"
              name="phone1"
              className="form-control"
              placeholder="Enter Mobile Number"
              value={phone1}
              onChange={this.fieldChangeHandler}
              onKeyPress={this.fieldChangeHandler}
            />
            <p className="error-msg">{phoneErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup className='aggreterms-form-group'>
          <div className="wa-checks"><input type="checkbox" checked={aggreeTerms} 
                    onChange={this.toggleaggreeTerms}/>
                    <label className='terms-and-conditions-text'>
                      {this.state.agreeTermsText1 && this.state.agreeTermsText1!='' && 
                          this.state.agreeTermsText1!=null && this.state.agreeTermsText1!=undefined && 
                          this.state.agreeTermsText1}
                      {this.state.agreeTermsURLText1 && this.state.agreeTermsURLText1!='' && 
                          this.state.agreeTermsURLText1!=null && this.state.agreeTermsURLText1!=undefined && 
                          this.state.agreeTermsURLLink1 && this.state.agreeTermsURLLink1!='' && 
                          this.state.agreeTermsURLLink1!=null && this.state.agreeTermsURLLink1!=undefined && 
                          <Link to={this.state.agreeTermsURLLink1}>{this.state.agreeTermsURLText1}</Link>}
                      
                      {this.state.agreeTermsText2 && this.state.agreeTermsText2!='' && 
                          this.state.agreeTermsText2!=null && this.state.agreeTermsText2!=undefined && 
                          this.state.agreeTermsText2}
                      {this.state.agreeTermsURLText2 && this.state.agreeTermsURLText2!='' && 
                          this.state.agreeTermsURLText2!=null && this.state.agreeTermsURLText2!=undefined && 
                          this.state.agreeTermsURLLink2 && this.state.agreeTermsURLLink2!='' && 
                          this.state.agreeTermsURLLink2!=null && this.state.agreeTermsURLLink2!=undefined && 
                          <Link to={this.state.agreeTermsURLLink2}>{this.state.agreeTermsURLText2}</Link>}
                      {this.state.agreeTermsText3 && this.state.agreeTermsText3!='' && 
                          this.state.agreeTermsText3!=null && this.state.agreeTermsText3!=undefined && 
                          this.state.agreeTermsText3}
                          </label>
                      </div>
                      <div className="row agreeterms-error-msg">
                    
                      <p className="error-msg">{agreeTermsErrMsg}</p>
                      </div>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>Pincode *</Label>
            <FormControl
              type="text"
              name="pincode"
              className="form-control"
              placeholder="Enter Pincode"
              value={pincode}
              onChange={this.fieldChangeHandler}
              onKeyPress={this.fieldChangeHandler}
            />
            <p className="error-msg">{pincodeErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>City *</Label>
            <FormControl
              type="text"
              disabled
              name="city"
              className="form-control"
              placeholder="City"
              value={city}
            />
            <p className="error-msg" />
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup className="requirements-select">
            <Label>Requirements *</Label>
            <Select
              components={Object.assign({}, animatedComponents, {
                Option,
              })}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              isMulti
              value={requirements}
              options={requirementsOptions}
              placeholder="Select"
              onChange={this.requirementsChangeHandler}
              classNamePrefix="leads-rqrmnts"
            />
            <p className="error-msg">{requirementsErrMsg}</p>
          </FormGroup>
        </Col>
        <Col xs={12}>
          <FormGroup>
            <h6 className="message">
              {errorMsg && <span className="failed">{errorMsg}</span>}
            </h6>
            <Button
              className="submit-btn btn-block btn-bg"
              // onClick={this.formSubmitHandler}
              onClick={this.formReviewHandler}
			  disabled={this.state.submitDisabled}
         id = 'rcb-submit'
            >
              SUBMIT
            </Button>
          </FormGroup>
        </Col>
        {/* <Col xs={12}
         className="disclaimer-section"
         >
          <h6 className="disclaimer">
            By submitting this form, you agree to the{' '}
            <Link to="/privacy-policy">privacy policy</Link> &{' '}
            <Link to="/termsconditions">terms and conditions</Link>
          </h6>
        </Col> */}
        {successMsg ? window.location.pathname='/thank-you' : null}
      </>
    );
  }
}

export default CustomerLeadsForm;
