import React from "react";
import "../../../public/styles/static-pages/consultForm.scss";
import {
  consultFormApi,
  consultGetApi,
  PinToCityAPI,
  espotAPI,
} from "../../../public/constants/constants";
import apiManager from "../../utils/apiManager";
import {
  FULL_NAME,
  MOBILE_NUMBER,
  FULLNAME_MSG,
  VALIDNAME_MSG,
  VALIDEMAIL_MSG,
  MOBILE_MSG,
  INVALID_PINCODE_FORMAT,
  NOT_VALID_PINCODE,
} from "../../constants/app/primitivesConstants";

import {
  regexEmail,
  regexMobileNo,
  validateEmptyObject,
  validateFullName,
  regexName,
} from "../../utils/validationManager";
import { triggerFormSubmissionGTEvent } from "../../utils/gtm";
import {
  BOOK_A_CONSULTATION_FORM_TYPE,
  FORM_POSITION,
} from "../../constants/app/gtmConstants";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/lib/Button'
import Modal from 'react-bootstrap/lib/Modal'
import Row from 'react-bootstrap/lib/Row'
import Col from 'react-bootstrap/lib/Col'
const PINCODE_REGEX = /^\d{6}$/;
class ConsultForm extends React.Component {
  constructor() {
    super();
    this.initialState = {
      name: "",
      email: "",
      mobileNumber: "",
      callWhatsapp: false,
      // dropDownValue: "",
      // message: "",
      dropDownArr: [],
      index: null,
      errorMessageName: null,
      errorMessageEmail: null,
      // errorMessageDropOption: null,
      errorMessageMobile: null,
      errorMessagePincode: null,
      errorMessageCity: null,
	  submitDisabled: false,
      // errorMessageDescription: null
      agreeTermsText1: '',
      agreeTermsText2: '',
      agreeTermsText3: '',
      agreeTermsURLText1: '',
      agreeTermsURLLink1: '',
      agreeTermsURLText2: '',
      agreeTermsURLLink2: '',
      agreeTermsErrMsg: '',
      confirmationPopupTitle: '',
      confirmationPopupText1: '',
      confirmationPopupText2: '',
      confirmationPopupText3: '',
      confirmationPopupText4: '',
      confirmationPopupText5: '',
      confirmationPopupurlText1: '',
      confirmationPopupurlText2: '',
      confirmationPopupurlText3: '',
      confirmationPopupurlText4: '',
      confirmationPopupurlLink1: '',
      confirmationPopupurlLink2: '',
      confirmationPopupurlLink3: '',
      confirmationPopupurlLink4: '',
      aggreeTerms: true,
      agreeTermsErrMsg: '',
      show: false,
    };
    this.state = this.initialState;
  }

  handleChange = e => {
    const { name, value } = e.target; // gets info from Form

    if (e.target.name == "name") {
      this.setState({
        errorMessageName: "",
      });
    } else if (e.target.name == "email") {
      this.setState({
        errorMessageEmail: "",
      });
    } else if (e.target.name == "mobileNumber") {
      // Check for number input only
      if (e.target.validity.valid == false && e.target.value != "") {
        e.target.value = this.state.mobileNumber;
        const { name, value } = e.target;
        this.setState({
          [name]: value,
        });
        return;
      }
      this.setState({
        errorMessageMobile: "",
      });
      // } else if (e.target.name == "message") {
      //   this.setState({
      //     errorMessageDescription: ""
      //   });
      // }
    } else if (
      e.target.name === "pincode" &&
      e.target.value &&
      PINCODE_REGEX.test(e.target.value) &&
      this.state.pincode !== e.target.value
    ) {
      this.fetchCityFromPin(e.target.value);
    }
    this.setState({
      [e.target.name]: e.target.value !== "" ? e.target.value : null,
      [name]: value,
    },()=>{
      const isValidate = this.handleValidation(this.state, true);
      if (isValidate === false) {
        this.setState({
          submitDisabled: true
        })
      }else{
        this.setState({
          submitDisabled: false
        })
      }
    });
    // console.log(JSON.stringify(this.state, null, 2));
  };

  checkWhatsapp = e => {
    this.setState(
      {
        callWhatsapp: !this.state.callWhatsapp,
      },
      () => {
        if (this.state.callWhatsapp) {
          document.getElementById("checkbox").classList.add("checkbox-checked");
        } else {
          document
            .getElementById("checkbox")
            .classList.remove("checkbox-checked");
        }
      }
    );
  };

  clearData = () => {
    // blank data
    this.setState({
      name: "",
      email: "",
      mobileNumber: "",
      pincode: "",
      city: "",
      // dropDownValue: "Select an option",
      message: "",
      index: null,
      errorMessageName: null,
      errorMessageEmail: null,
      // errorMessageDropOption: null,
      errorMessageMobile: null,
      // errorMessageDescription: null,
      errorMessagePincode: null,
      errorMessageCity: null,
    });
  };

  fetchCityFromPin = pincode => {
    if (!pincode) return;
    apiManager
      .get(PinToCityAPI + pincode)
      .then(response => {
        // alert(JSON.stringify(response, null, 2));
        const { data } = response;
        if (data.data && data.data.city) {
          this.setState({
            errorMessagePincode: null,
            city: data.data.city,
            state: data.data.state,
          }, ()=>{
      const isValidate = this.handleValidation(this.state, true);
      if (isValidate === false) {
        this.setState({
          submitDisabled: true
        })
      }else{
        this.setState({
          submitDisabled: false
        })
      }
    });
          // alert("here");
        } else {
           this.setState({ errorMessagePincode: NOT_VALID_PINCODE,  city: "",
          state: "" });
        }
      })
      .catch(error => {
        this.setState({ errorMessagePincode: NOT_VALID_PINCODE,  city: "",
          state: "" });
        // alert("here");
      });
  };

  successMassage = () => {
    if (error !== null) {
      alert("Thank you for the feed back");
    }
  };

  onKeyPress = event => {
    if (event.key === "Enter") {
      const isValidate = this.handleValidation(this.state, true);
      if (isValidate === false) {
        return false;
      }
      this.callConsultApi();
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  }
  formReviewHandler = () => {
    if (!this.handleValidation(this.state, true)) return;
    this.setState({ show: true });
  }

  submitForm = e => {
    e.preventDefault();
    const isValidate = this.handleValidation(this.state, true);
    if (isValidate === false) {
      return false;
    }
    this.setState({
      submitDisabled: true,
    });
    setTimeout(() => {
      this.setState({
        submitDisabled:false,
      })
    }, 5000);
    // this.handleChange(e);
    this.callConsultApi();
    if (window.location.pathname == "/online-furniture-kitchen") {
      this.gtag_report_conversion();
    }
  };

  // For Error Block
  getErrorMessageBlock = message => <p className="error-msg">{message}</p>;

  /* Handle Validation */
  handleValidation = (obj, errorType) => {
    // alert(obj.pincode);
    let isValidate = errorType;
    const isEmailEntered = validateEmptyObject(obj.email);
    const isMobileEntered = validateEmptyObject(obj.mobileNumber);
    this.setState({
      errorMessageName: null,
      errorMessageEmail: null,
      // errorMessageDropOption: null,
      errorMessageMobile: null,
      // errorMessageDescription: null,
      errorMessagePincode: null,
      errorMessageCity: null,
    });

    // For name validation
    if (!validateEmptyObject(obj.name)) {
      this.setState({
        errorMessageName: FULLNAME_MSG,
      });
      isValidate = false;
    } else if (!validateFullName(obj.name) || !regexName.test(obj.name)) {
      this.setState({
        errorMessageName: VALIDNAME_MSG,
      });
      isValidate = false;
    }

    // For email validation
    if (!validateEmptyObject(obj.email) /* && !isMobileEntered */) {
      this.setState({
        errorMessageEmail: VALIDEMAIL_MSG,
      });
      isValidate = false;
    } else if (isEmailEntered && !regexEmail.test(obj.email)) {
      this.setState({
        errorMessageEmail: VALIDEMAIL_MSG,
      });
      isValidate = false;
    }
    if(obj.aggreeTerms==false){
      this.setState({ agreeTermsErrMsg: 'Please select terms and conditions' });
      isValidate = false;
    }
    // For drop option validation
    // if (obj.index === null) {
    //   this.setState({
    //     errorMessageDropOption: SELECT_OPTION_MSG
    //   });
    //   isValidate = false;
    // }

    // For mobile validation
    // if (!validateEmptyObject(obj.mobileNumber) && !isEmailEntered) {
    if (!validateEmptyObject(obj.mobileNumber)) {
      this.setState({
        errorMessageMobile: MOBILE_MSG,
      });
      isValidate = false;
    } else if (isMobileEntered && !regexMobileNo.test(obj.mobileNumber)) {
      this.setState({
        errorMessageMobile: MOBILE_MSG,
      });
      isValidate = false;
    }
    if (!validateEmptyObject(obj.pincode)) {
      this.setState({ errorMessagePincode: "This field is required" });
      isValidate = false;
    } else if (
      !PINCODE_REGEX.test(obj.pincode) ||
      !validateEmptyObject(obj.city)
    ) {
      // alert("here");
      this.setState({ errorMessagePincode: INVALID_PINCODE_FORMAT });
      isValidate = false;
    }

    // For message validation
    // if (obj.message == "") {
    //   this.setState({
    //     errorMessageDescription: ENTER_MSG
    //   });
    //   isValidate = false;
    // }
    // alert(isValidate);
    return isValidate;
  };

  callConsultApi = () => {
    // const contact_id = Date.now().toString()+ Math.floor(Math.random()*1000).toString();

    triggerFormSubmissionGTEvent(
      BOOK_A_CONSULTATION_FORM_TYPE,
      FORM_POSITION[this.props.sourcePage || "default"]
    );
    // const message = this.state.message.split("&").join("and");
    const urlSearchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    const data = {
      name: this.state.name,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email,
      // dropDownValue: this.state.dropDownValue,
      dropDownValue: this.props.source,
      city: this.state.city,
      state: this.state.state,
      pincode: this.state.pincode,
      callWhatsapp: this.state.callWhatsapp,
      utmPlacement: urlSearchParams.get("utm_placement")
        ? urlSearchParams.get("utm_placement")
        : "",
      utmMedia: urlSearchParams.get("utm_medium")
        ? urlSearchParams.get("utm_medium")
        : "",
      utmKeyword: urlSearchParams.get("utm_keyword")
        ? urlSearchParams.get("utm_keyword")
        : "",
      utmAddgroup: urlSearchParams.get("utm_adgroup")
        ? urlSearchParams.get("utm_adgroup")
        : "",
      utmCampaign: urlSearchParams.get("utm_campaign")
        ? urlSearchParams.get("utm_campaign")
        : "",
      utmSource: urlSearchParams.get("utm_source")
        ? urlSearchParams.get("utm_source")
        : "",
        aggreeTerms: this.state.aggreeTerms,
    };
    apiManager
      .post(consultFormApi, data)
      .then(res => {
        // alert(FEEDBACK_MSG);
        this.clearData();
        window.location.pathname = "/thank-you";
      })
      .catch(error => {
        this.setState({
          error,
        });
      });

    /* var myHeaders = new Headers();
      myHeaders.append("Content-Type", "multipart/form-data;");

      var formdata = new FormData();

      formdata.append("campaign", "IBM: Some value");
      formdata.append("contact_id", contact_id);
      formdata.append("contact_name", this.state.name);
      formdata.append("contact_phone", this.state.mobileNumber);
      formdata.append("contact_city", "Mumbai");
      formdata.append("contact_email", this.state.email);
      formdata.append("contact_topic", this.state.dropDownValue);
      formdata.append("contact_message", this.state.message);
      formdata.append("contact_source", "INTGodrejInterio : some static value");


      formdata.append("CONTACT_ID", contact_id);
      formdata.append("CONTACT_NAME", this.state.name);
      formdata.append("CONTACT_PHONE", this.state.mobileNumber);
      formdata.append("CONTACT_CITY", "Mumbai");
      formdata.append("CONTACT_EMAIL", this.state.email);
      formdata.append("CONTACT_TOPIC", this.state.dropDownValue);
      formdata.append("CONTACT_MESSAGE", this.state.message);
      formdata.append("CONTACT_SOURCE", "INTGodrejInterio : some static value");


      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(callCentre1Api, requestOptions)
        .then(response => response.text())
        .then(result => {})
        .catch(error => {});


      fetch(callCentre2Api, requestOptions)
        .then(response => response.text())
        .then(result => {}})
        .catch(error => {}}); */
  };

  getConsultDropDownApi = () => {
    const sourcePage = this.props.sourcePage || "default";
    const consultGetApiUrl = `${consultGetApi}?sourcePage=${sourcePage}`;
    apiManager
      .get(consultGetApiUrl)
      .then(response => {
        const { data } = response || {};
        this.setState({
          dropDownArr: data.data.consultationData,
          dropDownValue:
            this.state.index !== null
              ? data.data.consultationData[this.state.index]
              : "",
        });
      })
      .catch(error => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  };

  fetchRequirementsOptions = () => {
    apiManager
      .get(`${espotAPI}GI_Customer_MKitchens_Lead_Rqrmnts`)
      .then(response => {
        const { data } = response;
        if (data && data.data) {
          this.setState({
            requirementsOptions: data.data.requirements || [],
            whatsappText:  data.data.whatsappText || '',
            agreeTermsText1:  data.data.agreeTermsText1 || '',
            agreeTermsText2:  data.data.agreeTermsText2 || '',
            agreeTermsText3:  data.data.agreeTermsText3 || '',
            agreeTermsURLText1:  data.data.agreeTermsURLText1 || '',
            agreeTermsURLLink1:  data.data.agreeTermsURLLink1 || '',
            agreeTermsURLText2:  data.data.agreeTermsURLText2 || '',
            agreeTermsURLLink2:  data.data.agreeTermsURLLink2 || '',
            confirmationPopupTitle: data.data.confirmationPopupTitle || '',
            confirmationPopupText1: data.data.confirmationPopupText1 || '',
            confirmationPopupText2: data.data.confirmationPopupText2 || '',
            confirmationPopupText3: data.data.confirmationPopupText3 || '',
            confirmationPopupText4: data.data.confirmationPopupText4 || '',
            confirmationPopupText5: data.data.confirmationPopupText5 || '',
            confirmationPopupurlText1: data.data.confirmationPopupurlText1 || '',
            confirmationPopupurlText2: data.data.confirmationPopupurlText2 || '',
            confirmationPopupurlText3: data.data.confirmationPopupurlText3 || '',
            confirmationPopupurlText4: data.data.confirmationPopupurlText4 || '',
            confirmationPopupurlLink1: data.data.confirmationPopupurlLink1 || '',
            confirmationPopupurlLink2: data.data.confirmationPopupurlLink2 || '',
            confirmationPopupurlLink3: data.data.confirmationPopupurlLink3 || '',
            confirmationPopupurlLink4: data.data.confirmationPopupurlLink4 || ''        
          });
        }
      })
      .catch(error => {});
  };

  toggleaggreeTerms = e =>{
    this.setState({aggreeTerms : e.target.checked});
    if(e.target.checked==true){
      this.setState({
        agreeTermsErrMsg: '',
      })
    }
  }

  componentDidMount() {
    this.fetchRequirementsOptions();
    this.getConsultDropDownApi();
    this.setState({
      submitDisabled: true
    })
  }

  gtag_report_conversion(url) {
    const callback = function() {
      if (typeof url !== "undefined") {
        window.location = url;
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-880478252/TUJdCKiCx9cBEKyQ7KMD",
      event_callback: callback,
    });
    return false;
  }

  createSelectItems = () => {
    let items = [];
    const currentIndex = this.state.index;
    items = this.state.dropDownArr.map((item, index) => (
      <option key={item} value={index} selected={currentIndex === index}>
        {item}
      </option>
    ));

    return items;
  };

  // onDropdownSelected = e => {
  //   if (
  //     e.target.value !== "null" &&
  //     this.state.dropDownValue !== e.target.value
  //   ) {
  //     this.setState({
  //       dropDownValue: this.state.dropDownArr[e.target.value],
  //       index: e.target.value,
  //       errorMessageDropOption: null
  //     });
  //   }
  // };

  render() {
    const {
      name,
      email,
      mobileNumber,
      // message,
      // dropDownValue,
      // index
      aggreeTerms,
      agreeTermsErrMsg
    } = this.state;
    let errorMessageName = null;
    let errorMessageEmail = null;
    // let errorMessageOption = null;
    let errorMessageMobile = null;
    // let errorMessageDescription = null;
    let errorMessagePincode = null;
    const errorMessageCity = null;
    if (this.state.errorMessageName) {
      errorMessageName = this.getErrorMessageBlock(this.state.errorMessageName);
    }
    if (this.state.errorMessageEmail) {
      errorMessageEmail = this.getErrorMessageBlock(
        this.state.errorMessageEmail
      );
    }
    // if (this.state.errorMessageDropOption) {
    //   errorMessageOption = this.getErrorMessageBlock(
    //     this.state.errorMessageDropOption
    //   );
    // }
    if (this.state.errorMessageMobile) {
      errorMessageMobile = this.getErrorMessageBlock(
        this.state.errorMessageMobile
      );
    }
    // if (this.state.errorMessageDescription) {
    //   errorMessageDescription = this.getErrorMessageBlock(
    //     this.state.errorMessageDescription
    //   );
    // }
    if (this.state.errorMessagePincode) {
      // alert("here");
      errorMessagePincode = this.getErrorMessageBlock(
        this.state.errorMessagePincode
      );
    }
    return (
      <>
      <Modal className='modal_LDM' show={this.state.show}>
              <Modal.Body>
                <Row>
                  <Col xs={12} md={12}>
                    <div className='ldm_modal'>
                    <h4 className='popup-title-text'>{this.state.confirmationPopupTitle}</h4>
                    <h6 className='popup-info-text'>
                      {this.state.confirmationPopupText1}
                      {this.state.confirmationPopupurlText1 && this.state.confirmationPopupurlText1!='' && 
                      this.state.confirmationPopupurlText1!=null && this.state.confirmationPopupurlText1!=undefined && 
                      this.state.confirmationPopupurlLink1 && this.state.confirmationPopupurlLink1!='' && 
                      this.state.confirmationPopupurlLink1!=null && this.state.confirmationPopupurlLink1!=undefined && 
                      <Link to={this.state.confirmationPopupurlLink1}>{this.state.confirmationPopupurlText1}</Link>
                      }
                      {this.state.confirmationPopupText2}
                      {this.state.confirmationPopupurlText2 && this.state.confirmationPopupurlText2!='' && 
                      this.state.confirmationPopupurlText2!=null && this.state.confirmationPopupurlText2!=undefined && 
                      this.state.confirmationPopupurlLink2 && this.state.confirmationPopupurlLink2!='' && 
                      this.state.confirmationPopupurlLink2!=null && this.state.confirmationPopupurlLink2!=undefined && 
                      <Link to={this.state.confirmationPopupurlLink2}>{this.state.confirmationPopupurlText2}</Link>
                      }
                      {this.state.confirmationPopupText3}
                      {this.state.confirmationPopupurlText3 && this.state.confirmationPopupurlText3!='' && 
                      this.state.confirmationPopupurlText3!=null && this.state.confirmationPopupurlText3!=undefined && 
                      this.state.confirmationPopupurlLink3 && this.state.confirmationPopupurlLink3!='' && 
                      this.state.confirmationPopupurlLink3!=null && this.state.confirmationPopupurlLink3!=undefined && 
                      <Link to={this.state.confirmationPopupurlLink3}>{this.state.confirmationPopupurlText3}</Link>
                      }
                      {this.state.confirmationPopupText4}
                      {this.state.confirmationPopupurlText4 && this.state.confirmationPopupurlText4!='' && 
                      this.state.confirmationPopupurlText4!=null && this.state.confirmationPopupurlText4!=undefined && 
                      this.state.confirmationPopupurlLink4 && this.state.confirmationPopupurlLink4!='' && 
                      this.state.confirmationPopupurlLink4!=null && this.state.confirmationPopupurlLink4!=undefined && 
                      <Link to={this.state.confirmationPopupurlLink4}>{this.state.confirmationPopupurlText4}</Link>
                      }
                      {this.state.confirmationPopupText5}
                    </h6>
                    </div>
                  </Col>
                </Row>
                <Row className='button-row'>
                  <Col xs={6} md={6}>
                    <Button
                    className="submit-btn btn-block btn-bg"
                    // onClick={this.formSubmitHandler}
                    onClick={this.submitForm}
                    >
                    {'SUBMIT'}              
                    </Button>
                  </Col>
                {/* </Row>
                <Row className='button-row'> */}
                  <Col xs={6} md={6}>
                    <Button
                    className="submit-btn btn-block btn-bg"
                    // onClick={this.formSubmitHandler}
                    onClick={this.handleClose.bind(this)}
                    >
                    {'CANCEL'}
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>

      <form className="consultForm">
        <div className="row">
          <div className="col-md-12 ">
            <div className="form-div clearfix div-error">
              <div className="form-group">
                <label className="form-labeled" htmlFor="name">
                  {FULL_NAME} *
                </label>
                <input
                  onKeyPress={this.onKeyPress}
                  onChange={this.handleChange}
                  onClick={this.handleChange}
                  className="form-control"
                  value={name}
                  id="name"
                  name="name"
                  type="text"
                  required
                />
                {errorMessageName}
              </div>
            </div>
          </div>{" "}
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-div clearfix div-error">
              <div className="form-group">
                <label className="form-labeled" htmlFor="email">
                  Email Address *
                </label>
                <input
                  onKeyPress={this.onKeyPress}
                  onChange={this.handleChange}
                  onClick={this.handleChange}
                  className="form-control"
                  id="email"
                  value={email}
                  name="email"
                  type="email"
                  required
                />
                {errorMessageEmail}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-div clearfix div-error">
              <div className="form-group">
                <label className="form-labeled" htmlFor="number">
                  {MOBILE_NUMBER} *
                </label>
                <input
                  onKeyPress={this.onKeyPress}
                  className="form-control"
                  onChange={this.handleChange}
                  pattern="[0-9]*"
                  value={mobileNumber}
                  id="mobileNumber"
                  type="mobile"
                  name="mobileNumber"
                  maxLength="10"
                  required
                />
                {errorMessageMobile}
              </div>
            </div>
          </div>
        </div>
          <div className="wa-checks"><input type="checkbox" checked={aggreeTerms} 
          onClick={this.handleChange}
          onChange={this.toggleaggreeTerms}/>
          <label className='terms-and-conditions-text'>
            {this.state.agreeTermsText1 && this.state.agreeTermsText1!='' && 
                this.state.agreeTermsText1!=null && this.state.agreeTermsText1!=undefined && 
                this.state.agreeTermsText1}
            {this.state.agreeTermsURLText1 && this.state.agreeTermsURLText1!='' && 
                this.state.agreeTermsURLText1!=null && this.state.agreeTermsURLText1!=undefined && 
                this.state.agreeTermsURLLink1 && this.state.agreeTermsURLLink1!='' && 
                this.state.agreeTermsURLLink1!=null && this.state.agreeTermsURLLink1!=undefined && 
                <Link to={this.state.agreeTermsURLLink1}>{this.state.agreeTermsURLText1}</Link>}
            
            {this.state.agreeTermsText2 && this.state.agreeTermsText2!='' && 
                this.state.agreeTermsText2!=null && this.state.agreeTermsText2!=undefined && 
                this.state.agreeTermsText2}
            {this.state.agreeTermsURLText2 && this.state.agreeTermsURLText2!='' && 
                this.state.agreeTermsURLText2!=null && this.state.agreeTermsURLText2!=undefined && 
                this.state.agreeTermsURLLink2 && this.state.agreeTermsURLLink2!='' && 
                this.state.agreeTermsURLLink2!=null && this.state.agreeTermsURLLink2!=undefined && 
                <Link to={this.state.agreeTermsURLLink2}>{this.state.agreeTermsURLText2}</Link>}
            {this.state.agreeTermsText3 && this.state.agreeTermsText3!='' && 
                this.state.agreeTermsText3!=null && this.state.agreeTermsText3!=undefined && 
                this.state.agreeTermsText3}
                </label>
            </div>
            <div className="row agreeterms-error-msg">
          <div className="col-md-12">
            <p className="error-msg">{agreeTermsErrMsg}</p>
            </div></div>



        {/* <div className="row">
          <div className="col-md-12 ">
            <div className="form-div clearfix div-error">
              <div className="form-group">
                <label className="form-labeled" htmlFor="dropdown">
                  {WHAT_YOU_LIKE[this.props.sourcePage] ||
                    WHAT_YOU_LIKE["default"]}
                </label>
                <select
                  name="dropDownValue"
                  onChange={this.onDropdownSelected}
                  onClick={this.onDropdownSelected.bind(this)}
                  className="form-control"
                  required
                >
                  <option value="Select an option" selected={!this.state.index}>
                    {SELECT_OPTION}s
                  </option>
                  {this.createSelectItems()}
                </select>
                {errorMessageOption}
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="row">
          <div className="col-md-12">
            <div className="form-div clearfix div-error">
              <div className="form-group">
                <label className="form-labeled" htmlFor="massage">
                  {MESSEGE[this.props.sourcePage] || MESSEGE["default"]}
                </label>
                <input
                  onKeyPress={this.onKeyPress}
                  className="form-control"
                  onChange={this.handleChange}
                  onClick={this.handleChange}
                  value={message}
                  id="message"
                  name="message"
                  type="text"
                  required
                />
                {errorMessageDescription}
              </div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-6">
            <div className="form-div clearfix div-error">
              <div className="form-group">
                <label className="form-labeled" htmlFor="number">
                  {/* {MOBILE_NUMBER} */}
                  Pincode *
                </label>
                <input
                  onKeyPress={this.onKeyPress}
                  className="form-control"
                  onChange={this.handleChange}
                  // pattern="[0-9]*"
                  // value={mobileNumber}
                  id="pincode"
                  type="number"
                  name="pincode"
                  maxLength="6"
                  minLength="6"
                  required
                />
                {errorMessagePincode}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-div clearfix div-error">
              <div className="form-group">
                <label className="form-labeled" htmlFor="name">
                  {/* {MOBILE_NUMBER} */}
                  City
                </label>
                <input
                  // onKeyPress={this.onKeyPress}
                  className="form-control"
                  // onChange={this.handleChange}
                  // pattern="[Aa-Za]*"
                  value={this.state.city}
                  id="city"
                  type="text"
                  name="city"
                  // maxLength="6"
                  // minLength="6"
                  required
                  disabled
                />
                {/* {errorMessageCity} */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-div clearfix div-error">
              <div className="form-group">
                <button
                  type="button"
                  // onClick={this.submitForm}
                  onClick={this.formReviewHandler}
                  className="btn btn-primary btn-submit"
				  disabled= {this.state.submitDisabled}
           id = {this.props.source=='Kitchen-BAC' ? 'bac-kitchen' : 'bac-default'}
                >
                  {/* {SEND} */}
                  BOOK A CONSULTATION
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <p>
          By submitting this form you agree to the <a href="https://www.godrejinterio.com/privacy-policy">privacy policy</a>{" "}
          & <a href="https://www.godrejinterio.com/termsconditions">T&C</a>
        </p> */}
      </form>
      </>
    );
  }
}

export default ConsultForm;
